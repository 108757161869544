import * as Sentry from '@sentry/sveltekit';
import { pb } from '$lib/pocketbase';
import { currentUser } from '$lib/stores/users';
import { PUBLIC_CURRENT_ENV, PUBLIC_SENTRY_DSN } from '$env/static/public';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [
		Sentry.replayIntegration(),
		Sentry.feedbackIntegration({
			colorScheme: 'system',
			showBranding: false,
			triggerLabel: '',
			formTitle: 'Provide Feedback',
			messagePlaceholder: 'Please share your feedback or suggestions here.',
			submitButtonLabel: 'Send Feedback',
			successMessageText: 'Thank you for your feedback!',
			showName: false,
			showEmail: false,
			isNameRequired: false,
			isEmailRequired: false
		})
	],
	ignoreErrors: ['ClientResponseError'],
	enabled: PUBLIC_CURRENT_ENV === 'prod'
});

pb.authStore.loadFromCookie(document.cookie);
pb.authStore.onChange(() => {
	// on change of the auth store, update currentUser var
	currentUser.set(pb.authStore.record as UserModel);

	Sentry.setUser({
		username: pb?.authStore?.record?.expand?.member?.name,
		email: pb?.authStore?.record?.expand?.member?.email
	});

	document.cookie = pb.authStore.exportToCookie({
		httpOnly: false,
		secure: PUBLIC_CURRENT_ENV === 'prod'
	});
}, true);

// TODO
// https://devdojo.com/jeblister/how-to-setup-an-authentication-user-management-on-sveltekit-using-pocketbase
export const handleError = Sentry.handleErrorWithSentry();
